import React from 'react';
import Layout from '../components/layout';
import Icon from '../components/icons';
import Img from 'gatsby-image';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import SEO from '../components/seo';

const iconStyle = {
    className: 'w-4 h-4 mr-1',
};

const iconMap = {
    Typ: <Icon.Triangle {...iconStyle} />,
    Format: <Icon.Lambda {...iconStyle} />,
    Nikotin: <Icon.Nicotine {...iconStyle} />,
    Vikt: <Icon.Weight {...iconStyle} />,
    Styrka: <Icon.Sigma {...iconStyle} />,
}

const InfoLine = (props) => {
    const { title, value, cap, suffix, other } = props;

    return (value || other) && title && iconMap[title] ? (
        <span className="flex items-center justify-between text-sm mb-1 max-w-350 w-full">
            <span className="flex items-center">
                {iconMap[title]} {title}:
            </span>
            {value && <span className={`${cap ? 'capitalize' : ''} ml-2 font-bold`}>{value} {suffix}</span>}
            {other && other}
        </span>
    ) : null;
};

export default ({ pageContext: { snus }, location: { pathname } }) => {
    // const strTxt = strength === 5
    //     ? 'Super strong'
    //     : strength === 4
    //     ? 'Extra strong'
    //     : strength === 3
    //     ? 'Strong'
    //     : strength === 2
    //     ? 'Normal'
    //     : 'Nikotinfri';
    const strArr = new Array(5).fill(false).fill(true, 0, snus.strength);
    return (
        <Layout>
            <SEO title={`${snus.brandName} | Jämför snuspriser online`} keywords={[snus.brand, snus.name, 'billigprilla.se']} pathname={pathname} />

                <div className="border rounded flex flex-col sm:flex-row p-3 sm:pl-0 mb-4 bg-white">
                    <div className="flex-1">
                        <Img
                            fluid={snus.productImage.childImageSharp.fluid}
                            className="w-64 h-64 mx-auto sm:mx-6 mb-3"
                        />
                    </div>
                    <div className="flex-1">
                        <h1 className="capitalize">
                            <span className="block text-xl sm:text-xl text-gray-700">
                                {snus.brand}
                            </span>
                            <span className="block text-2xl sm:text-2xl font-semibold mb-3">
                                {snus.name}
                            </span>
                        </h1>
                        <InfoLine
                            title="Styrka"
                            other={
                                <span className="inline-flex items-center">
                                    {strArr.map((s, i) => (
                                        <span
                                            key={i}
                                            className={`w-4 h-4 inline-block border rounded-full border-gray-700 ${s ? 'bg-gray-700' : 'bg-white'} ${i !== 4 && 'mr-1'}`}
                                        />
                                    ))}
                                </span>
                            }
                        />
                        <InfoLine title="Typ" cap value={snus.type} />
                        <InfoLine title="Format" cap value={snus.format} />
                        <InfoLine title="Nikotin" value={snus.nicotine} suffix="mg/g" />
                        <InfoLine title="Vikt" value={snus.weight} suffix="g" />
                    </div>
                </div>

                <div
                    className="rounded bg-white"
                >
                    <div className="flex justify-between border rounded-t px-3 py-2">
                        <b>Butik</b> <b className="mr-6">Pris</b>
                    </div>
                    <ul>
                        {snus.prices.map((price, i) => {
                            const url = price.url.indexOf('?') === -1 ? `${price.url}?utm_source=billigprilla-work-with-me` : `${price.url}&utm_source=billigprilla-work-with-me`;
                            return (
                                <li key={i} className="border-l-2 hover:border-primary hover:shadow">
                                    <OutboundLink
                                        href={url}
                                        target="_blank"
                                        rel="nofollow noopener"
                                        title={`${snus.brandName} hos ${price.reseller.resellerName}`}
                                        className="flex justify-between items-center border-b border-r px-3 py-2"
                                    >
                                        <span style={{ textTransform: 'capitalize' }}>{price.reseller.resellerName}</span>

                                        <span style={{ textAlign: 'right', marginLeft: 'auto' }}>{price.price} kr<br />
                                            <small>+ {price.reseller.shippingPrice} kr frakt</small>
                                        </span>

                                        <Icon.ArrowRight className="w-4 h-4 ml-2" />
                                    </OutboundLink>
                                </li>
                        )})}
                    </ul>
                </div>

        </Layout>
    );
}
